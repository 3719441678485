.projects-component-container {
    color: var(--white-color);
}

.projects-component-container > h2 {
    color: var(--light-blue-color);
    font-weight: var(--bold-font-weight);
    font-size: var(--h2-font-size);
    margin: 0;
}

.projects-component-container > p {
    color: var(--white-color);
    font-size: var(--h6-font-size);
    margin: 0 0 24px 0;
}

.projects {
    display: grid;
    border: 2px solid var(--light-blue-color);
    border-radius: 8px;
    padding-left: 10px;
    margin-top: 30px;
}

.project {
    display: grid;
    grid-template-rows: 2fr 0.5fr 0.5fr;
    row-gap: 12px;
    padding: 0 10px 10px 0px;
}

.project-intro > h3 {
    margin: 10px 10px 0 0;
    color: var(--light-blue-color);
    font-size: var(--h3-font-size);
    padding-bottom: 2px;
}

.project-intro > p {
    color: var(--white-color);
    margin: 0 10px 0 0;
    font-size: var(--body-font-size);
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 10px 10px 0;
}