@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');

:root {
  --light-blue-color: rgb(155, 159, 220);
  --white-color: rgb(229, 232, 224);
  --gray-color: rgb(109, 109, 109);
  --black-color: rgb(26, 26, 26);
  --h1-font-size: 32px;
  --h2-font-size: 28px;
  --h3-font-size: 24px;
  --h4-font-size: 20px;
  --h5-font-size: 18px;
  --h6-font-size: 16px;
  --button-font-size: 16px;
  --button-line-height: 24px;
  --body-font-size: 16px;
  --caption-font-size: 16px;
  --bold-font-weight: 700;
  --semibold-font-weight: 600
}

.App{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  margin: 0 480px;
}

body {
  font-family: 'Barlow', sans-serif;
  background-color: var(--black-color);
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.footer {
  background-color: var(--light-blue-color);
  color: var(--black-color);
  height: 40px;
  width: 100%;
}

@media (max-width: 1919px) {
  .content,
  .nav-container,
  footer > p {
    margin: 0 320px;
  }
}

@media (max-width: 1439px) {
  .content,
  .nav-container,
  footer > p {
    margin: 0 120px;
  }
}

@media (max-width: 1023px) {
  .content,
  .nav-container,
  footer > p {
    margin: 0 48px;
  }
}

@media (max-width: 800px) {
  header {
    padding: 189px 0 120px 0;
  }
  .projects {
    grid-template-columns: none;
    padding-left: 0px;
  }
  .project {
    padding-left: 10px;
    grid-template-rows: none;
  }
  .project-btn {
    align-self: center;
  }
  .about-me-wrapper {
    margin: auto;
    width: 100%;
  }
  .img-row {
    gap: 6px;
    flex-direction: row;
  }
  .about-me-img-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px
  }
  .paragraph-container {
    order: -1
  }
  .paragraph-container > p {
    padding: 0 0 10px 0;
    max-width: 75ch;
  }
  footer {
    text-align: center;
  }
  footer > p {
    font-size: 14px;
  }
  .desktop-nav {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  #exitButton {
    color: var(--black-color);
  }
}

@media (max-width: 425px) {
  .img-row img {
    width: 135px;
  }
  footer > p {
    font-size: 11px;
  }
}

@media (max-width: 375px) {
  .img-row img {
    width: 100px;
  }
  footer > p {
    font-size: 11px;
  }
}

@media (max-width: 320px) {
  footer > p {
    font-size: 11px;
    padding: 12px 0;
  }
}