.caption {
    position: relative;
    color: var(--black-color);
    background-color: var(--light-blue-color);
    border-radius: 8px;
    padding: 6px 12px;
    align-self: center;
}

.caption > h6 {
    font-size: var(--body-font-size);
    margin: auto;
}