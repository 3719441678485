footer > p {
    font-weight: var(--bold-font-weight);
    padding: 10px 0;
    margin: 0 0 0 480px;
    font-size: var(--body-font-size);
}

.hannah-moore-link:any-link {
    font-weight: var(--bold-font-weight);
    color: var(--black-color);
}