header {
    padding: 259px 0 200px 0;
}

.introduction {
    color: var(--light-blue-color);
    font-size: var(--button-font-size);
    line-height: var(--button-line-height);
    letter-spacing: 2px;
    padding: 2px 0;
    margin: 0;
    font-weight: bold;
}

.my-name {
    color: var(--white-color);
    font-size: var(--h1-font-size);
    padding: 2px 0;
    margin: 0;
}

.statement {
    color: var(--gray-color);
    font-size: var(--h1-font-size);
    padding: 2px 0;
    margin: 0;
}

.headline {
    color: var(--white-color);
    font-size: var(--h4-font-size);
    margin-top: 24px;
}

hr {
    height: 2px;
    background-color: var(--gray-color);
    border: none;
    mix-blend-mode: difference;
    margin: 48px 0;
}

.say-hello {
    color: var(--light-blue-color);
    font-size: var(--h2-font-size);
    font-weight: var(--bold-font-weight);
    margin-bottom: 10px;
}

.contact-me-btn {
    background-color: var(--black-color);
    color: var(--light-blue-color);
    border-radius: 8px;
    border: 2px solid var(--light-blue-color);
    padding: 6px 0;
    width: 100%;
    margin-bottom: 48px;
    font-size: var(--button-font-size);
    font-weight: var(--bold-font-weight);
}

.contact-me-btn:hover,
.contact-me-btn:focus {
    background-color: var(--light-blue-color);
    color: var(--black-color);
}