.about-me {
    color: var(--light-blue-color);
    font-size: var(--h2-font-size);
    font-weight: var(--bold-font-weight);
    margin-bottom: 10px;
}

.about-me-img-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 12px;
}

.img-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 12px;
}

.img-row > img {
    border-radius: 8px;
    width: 150px;
}

.paragraph-container > p {
    color: var(--white-color);
    font-size: var(--h6-font-size);
    width: 100%;
    margin: 0;
}

.paragraph-container > p {
    margin: 0;
    padding: 0;
}